
import { defineComponent } from 'vue';
import store from '@/store';

export default defineComponent({
    name: 'PlayerDetail',
    data() {
        return {
            playerDetailsPlayoffs: [],
            playerDetails: [],
            playerInfos: [],
            season: '2021',
        }
    },
    computed: {
        playerDetail(): any {
            return this.playerDetails;
        },
        playerDetailsPlayoff() {
            return this.playerDetailsPlayoffs;
        },
        playerInfo() {
            return this.playerInfos;
        },
    },
    methods: {
        getImgUrl(id: number): any {
            if (id) {
                return require('@/assets/teamLogos/team_' + id + '.png');
            }
        },
        matchDetail(id: number): void {
            store.commit("setMatchId", id);
            this.$router.push('/match-detail');
        },
        async getPlayerInfo() {
            await store.dispatch('getPlayerInfo').then((response: any) => {
                this.playerInfos = response;
            });
        },
        async getPlayerDetails() {
            await store.dispatch('getPlayerDetails', this.season).then(() => {
                this.playerDetails = store.getters.playerDetails;
                this.playerDetailsPlayoffs = store.getters.playerDetailsPlayoffs;
            });
        }
    },
    async beforeMount() {
        this.getPlayerDetails();
        this.getPlayerInfo();
    }
});
